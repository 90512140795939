import { Icon } from "../../../../components/BaseComponents/Icon";

export const AgeToggleFilterSelectedIcon = (props) => (
	<Icon fill="none" viewBox="0 0 8 6" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M7.72.3a1 1 0 01-.03 1.42l-4.17 4a1 1 0 01-1.39 0L.31 3.98a1 1 0 111.38-1.45l1.13 1.09L6.31.28A1 1 0 017.72.3z"
			clipRule="evenodd"
		/>
	</Icon>
);
