import { Icon } from "../../../../components/BaseComponents/Icon";

export const AgeToggleFilterCalenderIcon = (props) => (
	<Icon fill="none" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M25.33 5.5h1.17A3.5 3.5 0 0130 9v17.5a3.5 3.5 0 01-3.5 3.5h-21A3.5 3.5 0 012 26.5V9a3.5 3.5 0 013.5-3.5h1.17V3.17c0-.65.52-1.17 1.16-1.17h2.34c.64 0 1.16.52 1.16 1.17V5.5h9.34V3.17c0-.65.52-1.17 1.16-1.17h2.34c.64 0 1.16.52 1.16 1.17V5.5zm1.17 22.17c.64 0 1.17-.53 1.17-1.17V13.71H4.33V26.5c0 .64.53 1.17 1.17 1.17h21zm-10.98-5.89c0 1.44-1.14 2.39-3.02 2.39-2.37 0-3.27-1.39-3.27-3.33v-.18c0-2.21 1.44-3.5 3.33-3.5 1.15 0 1.97.32 2.42.96.36.49-.14 1.05-.75 1.05-.26 0-.5-.14-.74-.28-.2-.11-.4-.22-.6-.27a1.31 1.31 0 00-.26-.02c-.8 0-1.25.65-1.3 1.8.34-.47.83-.8 1.74-.8 1.34 0 2.45.76 2.45 2.18zm-4.05 0c0 .6.4 1 1 1s1-.4 1-.98c0-.57-.38-.98-1-.98-.6 0-1 .42-1 .96zm7.05-.99c0 1.27.4 1.87 1.31 1.87.92 0 1.28-.62 1.28-1.87v-.28c0-1.24-.4-1.87-1.3-1.87-.89 0-1.3.62-1.3 1.87v.28zm4.71-.04c0 2.15-1.34 3.42-3.46 3.42-2.07 0-3.38-1.23-3.38-3.37v-.28c0-2.15 1.47-3.35 3.42-3.35s3.42 1.1 3.42 3.31v.27z"
			clipRule="evenodd"
		/>
	</Icon>
);
